import { createSlice } from '@reduxjs/toolkit'
import { api } from '../apiSlice'
import TAGS from '../cacheTags'

const extendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAllTaxRegions: build.query({
            query: () => `/taxes/regions/?page=1&page_size=999`,
            providesTags: [TAGS.taxRegions],
        }),
        createTaxRegion: build.mutation({
            query: (data) => ({
                url: `/taxes/regions/`,
                method: 'POST',
                body: data,
            }),
        }),
        updateTaxRegion: build.mutation({
            query: ({ id, ...data }) => ({
                url: `/taxes/regions/${id}/`,
                method: 'PATCH',
                body: data,
            }),
        }),
        deleteTaxRegion: build.mutation({
            query: ({ id }) => ({
                url: `/taxes/regions/${id}/`,
                method: 'DELETE',
                body: {},
            }),
        }),
        bulkCreateTaxRegions: build.mutation({
            query: (data) => ({
                url: `/taxes/regions/bulk-create/`,
                method: 'POST',
                body: {
                    tax_regions: data,
                },
            }),
        }),
        bulkDeleteTaxRegions: build.mutation({
            query: (data) => ({
                url: `/taxes/regions/bulk-delete/`,
                method: 'POST',
                body: {
                    tax_region_ids: data,
                },
            }),
        }),
    }),
})

const taxRegionsSlice = createSlice({
    name: 'taxRegion',
    initialState: {
        showTaxDetailDrawer: false,
    },
    reducers: {
        setShowTaxDetailDrawer(state, action) {
            // eslint-disable-next-line no-param-reassign
            state.showTaxDetailDrawer = action.payload
        },
    },
})

export const {
    useGetAllTaxRegionsQuery,
    useLazyGetAllTaxRegionsQuery,
    useUpdateTaxRegionMutation,
    useDeleteTaxRegionMutation,
    useCreateTaxRegionMutation,
    useBulkCreateTaxRegionsMutation,
    useBulkDeleteTaxRegionsMutation,
} = extendedApi

export const { setShowTaxDetailDrawer } = taxRegionsSlice.actions

export const { reducer } = taxRegionsSlice
