const TAGS = {
    users: 'Users',
    roles: 'Roles',

    product: 'Product',
    products: 'Products',
    productUses: 'ProductUses',
    variant: 'Variant',
    variants: 'Variants',
    variantUses: 'VariantUses',
    bulkVariantUses: 'BulkVariantUses',

    brand: 'Brand',
    brands: 'Brands',
    brandCurrencyOverrides: 'BrandCurrencyOverrides',
    brandPaymentMethods: 'BrandPaymentMethods',

    offer: 'Offer',
    offers: 'Offers',
    offerProducts: 'OfferProducts',
    offerBundles: 'OfferBundles',
    offerCurrencyOverrides: 'OfferCurrencyOverrides',
    offerShippingOverrides: 'OfferShippingOverrides',
    offerVariantShippingRates: 'OfferVariantShippingRates',
    offerGroups: 'OfferGroups',
    offerPaymentMethods: 'offerPaymentMethods',
    offerStripeConfig: 'OfferStripeConfig',

    payins: 'Payins',

    companies: 'Companies',

    currencies: 'Currencies',
    currencyCountryMappings: 'CurrencyCountryMappings',

    transactionItem: 'TransactionItem',
    transactionItems: 'TransactionItems',

    warehouse: 'Warehouse',
    warehouses: 'Warehouses',
    warehouseRegions: 'WarehouseRegions',

    upsell: 'Upsell',
    upsells: 'Upsells',
    upsellContents: 'UpsellContents',

    discount: 'Discount',
    discounts: 'Discounts',

    kits: 'Kits',

    bundle: 'Bundle',
    bundles: 'Bundles',
    bundleProducts: 'BundleProducts',
    bundlePrices: 'BundlePrices',
    bundleUses: 'BundleUses',
    bulkBundleUses: 'Bulk Bundle Uses',

    categories: 'Categories',

    order: 'Order',
    orders: 'Orders',
    orderReturns: 'OrderReturns',
    returnReasons: 'ReturnReasons',
    notes: 'Notes',

    domains: 'Domains',
    domainMigrations: 'DomainMigrations',
    brandMigrations: 'BrandMigrations',

    taxRegions: 'TaxRegions',

    stripeDispute: 'StripeDispute',
    stripeDisputes: 'StripeDisputes',
    stripePaymentConfigs: 'StripePaymentConfigs',
    stripePaymentConfig: 'StripePaymentConfig',

    fulfillment: 'Fulfillment',
    fulfillmentShippingZones: 'FulfillmentShippingZones',
    shipHeroCustomer: 'shipHeroCustomer',
    shipHeroCustomers: 'shipHeroCustomers',

    paymentsProviders: 'PaymentsProviders',
    paymentsConfig: 'PaymentConfig',

    customerServiceProvider: 'CustomerServiceProvider',
    customerServiceProviders: 'CustomerServiceProviders',

    customAppProvider: 'CustomAppProvider',
    customAppProviders: 'CustomAppProviders',

    reporting: 'Reporting',

    emailProviders: 'EmailProviders',
    emailProvider: 'EmailProvider',
    emailProviderList: 'EmailProviderList',

    supportedPayments: 'SupportedPayments',

    marketingPartners: 'MarketingPartners',
    externalDomains: 'ExternalDomains',

    featureFlags: 'FeatureFlags',
}

export default TAGS
